import React from "react"
import { FaCode, FaSketch, FaServer } from "react-icons/fa"
export default [
  {
    id: 1,
    icon: <FaCode className="service-icon" />,
    title: "web development",
    text: `Web development refers to how the site is written and deployed. Currently some of the tools I use for development are: wordpress, netlify, shopify, gatsby, and sanity.`,
  },
  {
    id: 2,
    icon: <FaSketch className="service-icon" />,
    title: "web design",
    text: `Web design is more than just aesthetics. User experience needs to be carefully crafted to where enjoyment comes  seamlessly. Display of branding, color, and pacing come together here allowing for a unique experience.`,
  },
  {
    id: 3,
    icon: <FaServer className="service-icon" />,
    title: "backend development ",
    text: `Having a well developed backend can be tricky, both businesses and customers rely on a well-built framework to transmit data. Currently I use sanity and graphql to build a stable enviorment which offers functionality and ease of use.  `,
  },
]
