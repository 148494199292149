import React from "react"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Services from "../components/Services"
import Jobs from "../components/Jobs"
import Projects from "../components/Projects"
import mywork from "../constants/projects"
import SEO from "../components/SEO"

export default ( {} ) => {
  const proj = mywork
  return ( 
    <Layout>
    <SEO title="Home" />
    <Hero />
    <Services />
    <Jobs />
    <Projects projects={proj} title="featured projects" showLink/>
    </Layout>
  )
}
// ...GatsbyImageSharpFluid
