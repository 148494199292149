import React from "react"
import Title from "./Title"
import { FaAngleDoubleRight } from "react-icons/fa"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import jobs from "../constants/jobs"

const Jobs = () => {
  const data = jobs
  
  const [value,setValue] = React.useState(0)
  const {company,position,date,desc} = data[value]

  return ( 
    <section className="section jobs">
      <Title title="experience"/>
      <div className="jobs-center">
        {/* btn container */}
        <div className="btn-container">
          {data.map((item,index)=>{
            return ( 
              <button
                key={item.id}
                onClick={()=> setValue(index)}
                className={`job-btn ${index === value &&
                "active-btn"}`}
              >
                {item.company}
              </button>
            )  
          })}
        </div>
        {/* job info */}
        <article className="job-info">
          <h3>{position}</h3>
          <h4>{company}</h4>
          <p className="job-date">{date}</p>
          {desc.map(item =>{
              return (
              <div key={item.id} className="job-desc">
                <FaAngleDoubleRight className="job-icon"></FaAngleDoubleRight>
                <p>{item.name}</p>
              </div>
              ) 
            })}
        </article>
    </div>
    <Link to="/about" className="btn center-btn">
      more info
    </Link>
  </section>
  ) 
}

export default Jobs
