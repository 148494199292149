export default [
  {
    "id": 1,
    "company": "Red Jam",
    "position": "Lead Developer",
    "date": "December 2019 - Present",
    "desc": [
      {
        "id":9,
        "name": "Red Jam is a web design studio that uses jam stack technologies to build powerful websites."
      },
      {
        "id":10,
        "name": "Using primarily Gatsby react framework I maintain the frontend of different websites with focus on meeting PWA based metrics."
      },
      {
        "id":11,
        "name": "Lightning fast loading times with lazy-loading and ideal image optimization."
      },
      {
        "id":12,
        "name": "Accessibility features and scalability for future ecommerce integration."
      },
    ]
  },
    {
      "id": 2,
      "company": "VB design",
      "position": "Creative Design Intern",
      "date": "May 2017 - September 2018",
      "desc": [
        {
          "id":1,
          "name": "Developed systems to structure content with focus on legibility; Grid layout, spacing, and typography." 
        },
        {
          "id":3,
          "name": "Learned fundamental color theory (or color psychology). "
        },
        {
          "id":4,
          "name": "Created basic schedules with gantt charts based on time estimates and daily efficiency."
        },
      ]
    },
    {
      "id": 3,
      "company": "Certificates",
      "position": "Google & University of Michigan",
      "date": "May 2015 - December 2015",
      "desc": [
        {
          "id":5,
          "name": "Google - Learned how to analyze data types and structures to solve real world problems. Primarily the courses focused on excel, sql, and tableau."
        },
        {
          "id":6,
          "name": "University of Michigan - Learned Python programming language with libraries focused on data science - Pandas; NumPy"
        },
      ]
    },
  ]


  {/*
  
  {
    "id": 1,
    "company": "Red Jam",
    "position": "Lead Developer",
    "date": "December 2019 - Present",
    "desc": [
      {
        "id":9,
        "name": "Prosaurolophus Caudipteryx Avaceratops Shunosaurus Campylodoniscus Paleosaurus Futalognkosaurus Sauroposeidon Blikanasaurus Huayangosaurus Kol Kentrosaurus Segisaurus Cryolophosaurus Rebbachisaurus."
      },
      {
        "id":10,
        "name": "Quae similitudo in genere etiam humano apparet. Quod si ita se habeat, non possit beatam praestare vitam sapientia. "
      },
      {
        "id":11,
        "name": "Quae similitudo in genere etiam humano apparet. Quod si ita se habeat, non possit beatam praestare vitam sapientia."
      },
      {
        "id":12,
        "name": "Heri, inquam, ludis commissis ex urbe profectus veni ad vesperum. Tum mihi Piso: Quid ergo? In qua si nihil est praeter rationem, sit in una virtute finis bonorum; Tu autem negas fortem esse quemquam posse, qui dolorem malum putet. Non quam nostram quidem, inquit Pomponius iocans; "
      },
    ]
  },
  
  */}