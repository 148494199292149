export default [
        {
            "github": "",
            "id": "Projects_3",
            "description": "Common Grounds is a local coffee shop who needed to increase online orders. Red Jam created a website which allowed for more traffic, increasing sales.",
            "title": "Local cafe",
            "url": "https://common-grounds.netlify.app/",
            "image": {
                "childImageSharp": {
                    "fluid": {
                        "src": 
                        '/common-grounds.png'
                    }
                }
            },
            "stack": [
                {
                    "id": "1",
                    "title": "html"
                },
                {
                    "id": "2",
                    "title": "css"
                },
                {
                    "id": "3",
                    "title": "gatsby"
                },
                {
                    "id": "4",
                    "title": "netlify cms"
                }
            ]
        },
        {
            "github": "",
            "id": "Projects_1",
            "description": "When one of the best names in hospitality needed a way to share winelists and build connections, Red Jam built an easy to use application making day-to-day easier.",
            "title": "Personal Platform",
            "url": "https://agitated-kowalevski-3c3ba3.netlify.app/",
            "image": {
                "childImageSharp": {
                    "fluid": {
                        "src": 
                        '/projects-1.png'
                    }
                }
            },
            "stack": [
                {
                    "id": "1",
                    "title": "html"
                },
                {
                    "id": "2",
                    "title": "css"
                },
                {
                    "id": "3",
                    "title": "gatsby"
                },
                {
                    "id": "4",
                    "title": "netlify cms"
                }
            ]
        },
        {
            "github": "",
            "id": "Projects_3",
            "description": "Highmos is an example website I created that can inspire you or provide ideas into your website. This simple static website has the potential to allow customers an easier way to book their stays.",
            "title": "Highmos",
            "url": "https://sad-fermi-cc9ba8.netlify.app",
            "image": {
                "childImageSharp": {
                    "fluid": {
                        "src": 
                        '/projects-2.png'
                    }
                }
            },
            "stack": [
                {
                    "id": "5",
                    "title": "html"
                },
                {
                    "id": "6",
                    "title": "css"
                },
                {
                    "id": "7",
                    "title": "figma"
                },
            ]
        },
]

{/*

 {
            "github": "",
            "id": "Projects_4",
            "description": "",
            "title": "Project 4",
            "url": "",
            "image": {
                "childImageSharp": {
                    "fluid": {
                        "src": 
                        '/projects-4.jpg'
                    }
                }
            },
            "stack": [
                {
                    "id": "10",
                    "title": "html"
                },
                {
                    "id": "11",
                    "title": "css"
                },
                {
                    "id": "12",
                    "title": "javascript"
                }
            ]
        },

*/}